













































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  exportOfflineProperties,
  getOfflineProperties,
  getOfflinePropertiesCount
} from '@/api/properties'
import {
  areasFilter, assignationTypeFilter,
  availableForFilter,
  clientsFilter,
  createFilter,
  dateFilter,
  getSortItems,
  prepareListFilters,
  propertyCategoryFilter,
  propertyStateFilter,
  propertyStatusFilter,
  propertyTypeFilter,
  stackedFieldsFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import PropertyItem from '@/views/search/components/PropertyItem.vue'
import {
  debounce,
  getOfficeLogoUrl,
  getTranslations
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import { redirectToSite } from '@/utils/property'

@Component({
  name: 'PropertyOfflineList',
  components: {
    Pagination,
    ListToolbar,
    PropertyItem,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count: number | null = null
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected = []
  private getTranslations = getTranslations
  private redirectToSite = redirectToSite
  private getOfficeLogoUrl = getOfficeLogoUrl
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)

  private filters = [
    availableForFilter(),
    propertyCategoryFilter(),
    propertyTypeFilter(),
    assignationTypeFilter(),
    propertyStateFilter(),
    propertyStatusFilter(),
    clientsFilter(),
    areasFilter('area.id,area.parent_id,area.parent.parent_id'),
    stackedFieldsFilter({
      key: 'floor',
      label: 'filters.floor',
      type: 'double'
    }),
    stackedFieldsFilter({
      key: 'price',
      label: 'filters.price'
    }),
    stackedFieldsFilter({
      key: 'acreage',
      label: 'filters.acreage'
    }),
    stackedFieldsFilter({
      key: 'views',
      label: 'filters.views'
    }),
    stackedFieldsFilter({
      key: 'kitchens',
      label: 'filters.kitchens'
    }),
    stackedFieldsFilter({
      key: 'living_rooms',
      label: 'filters.living_rooms'
    }),
    dateFilter({
      key: 'available_from',
      label: 'filters.available_from'
    }),
    dateFilter()
  ]

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: [{
        key: 'full_code'
      }],
      filters: filters
    })
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportOfflineProperties(this.list)
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getOfflinePropertiesCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getOfflineProperties(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
      this.count = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  @Watch('search')
  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
